import { FormControl, FormGroup } from '@angular/forms';
import { Category } from '../../domain/category/category.model';
import { City } from '../../domain/city/city.model';
import { Province } from '../../domain/province/province.model';
import { Specialty } from '../../domain/specialty/specialty.model';
import { ProfessionType } from '../../domain/profession/profession.model';
import { AgenasDisciplineType } from 'src/app/domain/agenas-discipline/agenas-discipline.model';

export const IDS_TO_SHOW_CATEGORY_FIELDS = [14, 58, 212, 248];
export const DOCTOR_ID = 14;

export const AUTOCOMPLETE_MIN_LETTERS = 2;

export enum UserFormType {
  SIGNUP = 'signup',
  EDIT = 'edit'
}


export interface UserForm {
  personalInfos: FormGroup<UserFormPersonalInfos>;
  professionalInfos: FormGroup<UserFormProfessionalInfos>;
  ecmInfos: FormGroup<UserFormEcmInfos>;
  consentsForm: FormGroup<UserFormConsentsForm>;
}

export interface UserFormPersonalInfos {
  name: FormControl<null | string>;
  surname: FormControl<null | string>;
  email: FormControl<null | string>;
  password: FormControl<null | string>;
  birthdate: FormControl<null | string>;
  gender: FormControl<null | string>;
  bornAbroad: FormControl<null | string>;
  birthplace: FormControl<null | City>;
  codiceFiscale: FormControl<null | string>;
}


export interface UserFormEcmInfos {
  cellulare: FormControl<null | string>;
  cap: FormControl<null | string>;
  professionPlace: FormControl<null | City>;
  professionType: FormControl<null | ProfessionType>;
  agenasDiscipline: FormControl<null | AgenasDisciplineType>;
}


export interface UserFormProfessionalInfos {
  category: FormControl<null | Category>;
  specialty: FormControl<null | Specialty>;

  registerForm: FormGroup<UserFormProfessionalInfosRegisterForm>;
}

export interface UserFormProfessionalInfosRegisterForm {
  registerNumber: FormControl<null | string>;
  registerProvince: FormControl<null | Province>;
}

export interface UserFormConsentsForm {
  marketing: FormControl<null | string>;
  marketingThirdParties: FormControl<null | string>;
  profiling: FormControl<null | string>;
  profilingThirdParties: FormControl<null | string>;
}

