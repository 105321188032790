import { createSelector } from '@ngrx/store';
import { ErrorMessage } from '../error/error-message.model';
import { agenasDisciplineSelectors, AgenasDisciplineState } from './agenas-discipline.entity';
import { selectAgenasDisciplineState } from './agenas-discipline.state';

export const selectAgenasDiscipline = createSelector(selectAgenasDisciplineState, agenasDisciplineSelectors.selectAll);

export const selectAgenasDisciplineError = createSelector(
  selectAgenasDisciplineState,
  (state: AgenasDisciplineState): ErrorMessage => state.error
);
